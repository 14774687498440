import { BottomNavigation, BottomNavigationAction, Box, Container, CssBaseline, Hidden, Icon, Menu, MenuItem, Paper, styled, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppSidebar from "../_components/sidebar/app.sidebar";
import { AppToolbar } from "../_components/toolbar/app.toolbar";
import { accountService } from "../_services/accounts.service";
import { BACKGROUND_COLOR } from "../_const/color.const";
import TrustpilotWidget from "../_components/trustpilot/trustBox.widget";
import { Footer } from "@mantine/core";

const drawerWidth = 240;

const useStyles = {

    appScreen: {
        display: 'flex',
        backgroundColor: "#e6e6e6",
        height: "100%",
        overflow: "auto !important",
    },
    main: {

        marginTop: "40px"
    },
    root: {
    },
    mainBackground: {},
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid #e6e6e6',
        zIndex: 9999
    },
    toolbar: {},
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    width: 'calc(100% - 500px)',
    maxWidth: "calc(100%)",
    paddingTop: theme.spacing(10),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    //    background: BACKGROUND_COLOR
}));


export function AppScreen() {
    const [value, setValue] = React.useState(0);
    const [isOpen, setIsOpen] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const goTo = useNavigate();

    const isMobile = useMediaQuery('(max-width:600px)');

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e: any, nav: any) => {
        setAnchorEl(null);
        if (nav && nav.url)
            goTo(nav.url);
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div style={useStyles.appScreen} id="app-screen">

            <AppToolbar toggleSidebar={toggleSidebar} isOpen={isOpen}></AppToolbar>

            <Main sx={{
                backgroundColor: {
                    xs: "#fff", sm: BACKGROUND_COLOR
                }
            }} open={isOpen}>
                {/*{!isMobile &&*/}
                {/*    <Paper elevation={3}*/}
                {/*    sx={{*/}
                {/*        width: "70%",*/}
                {/*        maxHeight: '100vh', // Set a maximum height to ensure the paper doesn't exceed the viewport*/}
                {/*        overflow: 'auto',*/}
                {/*        borderRadius: '40px!important',*/}
                {/*        margin: '35px auto',*/}
                {/*        //marginTop: '35px',*/}
                {/*        //marginLeft: '130px',*/}
                {/*        //marginRight: '130px'*/}
                {/*    }}>*/}
                {/*    <Outlet></Outlet>*/}
                {/*</Paper>}*/}

                {!isMobile &&
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight="90%"
                    >
                        <Outlet></Outlet>
                    </Box>
                }
                {isMobile &&
                    <Box
                        width="100%"
                        margin="25px 0 25px 0"
                        marginBottom="100px"
                    >
                        <Outlet></Outlet>
                    </Box>
                }

                {!isMobile ?
                    <Box marginTop={{ xs: '500px', md: 'auto' }}
                        sx={{
                            //marginTop: "auto",
                            p: 4,
                            backgroundColor: "#FFF",
                            boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
                        }}
                        component="footer"
                    >

                        <TrustpilotWidget />
                    </Box>
                    :

                    <>
                        <Box height={{ xs: "80px", md: "150px" }}></Box>
                        <Paper sx={{
                            marginTop: 'calc(10% + 60px)',
                            width: '100%',
                            position: 'fixed',
                            bottom: 0,
                            boxShadow: "0px 5px 10px #0000001A",
                        }} component="footer" variant="outlined">
                            <Container maxWidth="lg">
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        justifyContent: "center",
                                        display: "flex",
                                        my: 1
                                    }}
                                >
                                    <div>
                                        <TrustpilotWidget />
                                    </div>
                                </Box>


                            </Container>
                        </Paper>
                    </>
                }
            </Main>
        </div >
    );
}