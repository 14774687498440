import { AppBar, Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { appointmentService } from "../../_services/appointment.service";
import { useEffect, useState } from "react";
import { servicesService } from "../../_services/services.service";
import { ServiceTypeEnum } from "../../_common/enum/setviceTypeEnum";
import { FREESTEPS, PAYMENTSTEPS, EXISTINGSTEPS, IStepsInterface, SERVICE_IMAGE } from "./consts/serviceConsts";
import { handleErrorResponse } from "../../_helpers/handleResponse.helper";
import { JourneyStepper } from "./userJourneyComponents/journeyStepper";
import { NavButtons } from "./userJourneyComponents/navButtons";
import { AppointmentPickerStep } from "./userJourneySteps/appointment-picker-step";
import { ServiceDetailsStep } from "./userJourneySteps/service-details-step";
import { PaymentStep } from "./userJourneySteps/payment-step";
import { ITimerOptions } from "../../_common/interface/timer/iTimerOptions";
import { TimerStatusEnum } from "../../_common/enum/timerStatus.enum";
import { JourneyTimer } from "./userJourneyComponents/journeyTimer";
import { useNavigate } from "react-router-dom";
import { getFormattedDateAndTime, getLongDateString } from "../../_helpers/dateTime.helper";
import { toastr } from "../../_components/toastr/toastr";
import { ICustomerAppointmentManage, resetCustomerAppointmentData } from "../../_common/interface/customerAppointment/iCustomerAppointmentManage";
import { PRIMARY_COLOR } from "../../_const/color.const";
import { CustomerDetailsStep } from "./userJourneySteps/customer-details.step";
import { JourneyStepsEnum } from "../../_common/enum/journeySteps.enum";
import { InitialDetailsStep } from "./userJourneySteps/initial-details-step";
import { AppointmentConfirmedStep } from "./userJourneySteps/appointment-confirmed-step";
import ServiceCardBox from "../../_components/box/serviceCardBox";
import TrustpilotWidget from "../../_components/trustpilot/trustBox.widget";
import { Footer } from "@mantine/core";



export const JourneyIndex = (params: {
    serviceType: ServiceTypeEnum,
}) => {
    const [data, setData] = useState<ICustomerAppointmentManage>(resetCustomerAppointmentData);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisableAllNav, setIsDisableAllNav] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [serviceDetails, setServiceDetails] = useState('');
    const [consultationType, setConsultationType] = useState('');
    const [imageOverlayText, setImageOverlayText] = useState('FREE');

    const [activePage, setActivePage] = useState(1);
    const [activeStep, setActiveStep] = useState(JourneyStepsEnum.ServiceDetailsStep);
    const [isAppointmentTimeSelected, setIsAppointmentTimeSelected] = useState('false');
    const [isIncomeBandSelected, setIsIncomeBandSelected] = useState(false);
    const [serviceId, setServiceId] = useState(0);
    const [servicePrice, setServicePrice] = useState(0);
    const [appointmentMaxDaysInAdvance, setAppointmentMaxDaysInAdvance] = useState(60);
    const [appointmentMinDaysInAdvance, setAppointmentMinDaysInAdvance] = useState(2);
    const [appointmentDateStr, setAppointmentDateStr] = useState('');
    const [stepperSteps, setStepperSteps] = useState<Array<IStepsInterface>>([]);
    const [isDiscountCodeApplied, setIsDiscountCodeApplied] = useState(false);
    const [isDiscountCodeValid, setIsDiscountCodeValid] = useState(true);
    const [serviceData, setServiceData] = useState<any>(null);
    const [formattedDateTime, setFormattedDateTime] = useState({ monthYear: '', dayWithSuffix: '', time: '' });

    const [timerOptions, setTimerOptions] = useState<ITimerOptions>({
        timerStatus: TimerStatusEnum.Paused,
        totalMainSecondsCountdown: 300,
        resetCounter: 0
    });

    const isMobile = useMediaQuery('(max-width:600px)');
    let discountCodeId = 0;

    let navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [params.serviceType]);

    useEffect(() => {
        if (data.appointmentDate == null) return;
        const formattedDateTime = getFormattedDateAndTime(data.appointmentDate);
        if (formattedDateTime == null) return;
        setFormattedDateTime(formattedDateTime);
    }, [data.appointmentDate]);
    function fetchData() {
        fetchServiceData();
    }

    function fetchServiceData() {
        setIsLoading(true);
        return servicesService
            .getServiceByServiceTypeEnum(params.serviceType)
            .then(
                (json) => {
                    setPaymentAmount(json.price)

                    let _data = { ...data }
                    _data.serviceId = json.id;
                    _data.appointmentDate = new Date();
                    _data.durationId = json.durationId;
                    setServicePrice(json.price ? json.price : 0);
                    setServiceDetails(json.info);
                    setServiceId(json.id);
                    setConsultationType(json.name);
                    setServiceData(json);

                    if (json.appointmentMaxDaysInAdvance != null) {
                        setAppointmentMaxDaysInAdvance(json.appointmentMaxDaysInAdvance);
                    }

                    if (json.appointmentMinDaysInAdvance != null) {
                        setAppointmentMinDaysInAdvance(json.appointmentMinDaysInAdvance);
                    }

                    if (json.price) {
                        setStepperSteps(PAYMENTSTEPS(params.serviceType));
                    } else {
                        setServicePrice(0);
                        if (params.serviceType === ServiceTypeEnum.Existing) {
                            setStepperSteps(EXISTINGSTEPS);
                        } else {
                            setStepperSteps(FREESTEPS);
                        }
                    }

                    setData(_data);
                }, handleErrorResponse
            )
            .finally(() => {
                setIsLoading(false);
            });
    }
    function onBack() {
        if (activePage === JourneyStepsEnum.ServiceDetailsStep) {
            setData(resetCustomerAppointmentData);
            navigate('/');
        } else {
            let previousStep = activeStep !== 1 ? activeStep - 1 : 1;
            setActiveStep(previousStep);
            setActivePage(stepperSteps[previousStep - 1].page);
        }
    }

    function onNext() {
        if (activePage === JourneyStepsEnum.AppointmentPickerStep) {
            setAppointmentDateStr(getLongDateString(data.appointmentDate));
        }

        if ((activePage === JourneyStepsEnum.AppointmentPickerStep
            || activePage === JourneyStepsEnum.CustomerDetailsStep) && servicePrice > 0) {
            appointmentSave();
        }

        if (activePage === JourneyStepsEnum.AppointmentPickerStep && servicePrice === 0) {
            // Can probably go into the if statement above
            appointmentSave();
        }

        if (activePage === JourneyStepsEnum.CustomerDetailsStep && (servicePrice === 0 || paymentAmount === 0)) {
            appointmentSaveAndComplete();
        } else {
            let nextStep = activeStep !== stepperSteps.length ? activeStep + 1 : stepperSteps.length
            setActiveStep(nextStep);
            setActivePage(stepperSteps[nextStep - 1].page);
        }

    }

    function handleChange(event: any) {
        if (event.name === "dateOfBirth") {
            let _data = { ...data }
            _data.dateOfBirth = event;
            setData(_data);
        } else if (event.name === 'mortgageTypeId') {
            setData(_mortType => {
                return {
                    ..._mortType,
                    mortgageTypeId: +(event.target.value)
                }
            });
        } else if (event.name === 'appointmentDate') {
            setIsAppointmentTimeSelected('true');

            // Gotta set the overlay text also on the image
            setImageOverlayText(getLongDateString(event.appointmentDate));

            setData(_apptDate => {
                return {
                    ..._apptDate,
                    appointmentDate: event.appointmentDate
                }
            });
        } else if (event.name === 'mobilePhoneNo') {
            setData(_mobilePhoneNo => {
                return {
                    ..._mobilePhoneNo,
                    mobilePhoneNo: event.value
                }
            });
        }

        if (event.target) {
            if (event.target.name === 'incomeBandId') {
                let _data = { ...data }
                _data.incomeBandId = +event.target.value;

                setData(_data);
                setIsIncomeBandSelected(true);
            } else if (event.target.name === 'isPreviousCreditIssues') {
                let _data = { ...data }
                if (event.target.value === 'yes') {
                    _data.isPreviousCreditIssues = true;
                } else {
                    _data.isPreviousCreditIssues = false;
                }
                setData(_data);
            } else if (event.target.name === 'isTermsAndConditionsAgree') {
                let _data = { ...data }
                _data.isTermsAndConditionsAgree = !_data.isTermsAndConditionsAgree;
                setData(_data);
            } else if (event.target.name === 'mortgageAmountRequired') {
                let _data = { ...data }
                _data.mortgageAmountRequired = +event.target.value;
                setData(_data);
            } else {
                setData((__data) => { return { ...__data, [event.target.name]: event.target.value } });
            }
        }
    }

    function onAppointmentTimeSelected(value: string) {
        setIsAppointmentTimeSelected(value);
    }

    function appointmentSave() {
        let _timerOptions = timerOptions;
        _timerOptions.timerStatus = TimerStatusEnum.Main;

        setTimerOptions(_timerOptions);

        let _appointmentData = { ...data }

        appointmentService
            .customerAppointmentSubmit(_appointmentData)
            .then(
                (d) => {
                    if (d.id) {
                        let _data = { ...data }

                        _data.id = d.id;
                        _data.customerId = d.customerId;
                        _data.advisorId = d.advisorId;

                        setData(_data);
                    }


                    let _timerOptions = timerOptions;
                    _timerOptions.timerStatus = TimerStatusEnum.Main;
                    setTimerOptions(_timerOptions);

                    return true;
                },
                (e) => {
                    toastr.error("Something went wrong. Please try again later");
                }
            )
            .finally(() => {
                
            });
    }

    async function appointmentSaveWithResolve() {
        let _appointmentData = { ...data }
        return new Promise(resolve => {
            resolve(appointmentService
                .customerAppointmentSubmit(_appointmentData));
        });
    }

    async function appointmentCompleteWithResolve() {
        let _appointmentData = { ...data }
        return new Promise(resolve => {
            resolve(appointmentService
                .customerAppointmentCreateComplete(_appointmentData));
        });
    }

    async function appointmentSaveAndComplete() {
        try {
            setTimerOptions({ ...timerOptions, timerStatus: TimerStatusEnum.Paused });

            let r1 = await appointmentSaveWithResolve();
            completeAppointmentBooking();

        } catch (error: any) {
            console.log('error saving and completing appointment: ', error);
        }
    }


    function submitPayment() {
        setTimerOptions({ ...timerOptions, timerStatus: TimerStatusEnum.Paused });
        // Waiting for payments stuff 
        completeAppointmentBooking();
    }

    function completeAppointmentBooking() {
        setIsDisableAllNav(true);

        let _appointmentData = { ...data }
        _appointmentData.paymentAmount = paymentAmount;

        appointmentService
            .customerAppointmentCreateComplete(_appointmentData)
            .then(
                (d) => {

                    if (serviceData?.isFInalStepUrlEnabled === true) {
                        window.location.href = serviceData?.finalStepUrl;
                    }
                    else {
                        setActivePage(JourneyStepsEnum.AppointmentConfirmed);
                    }
                    setData(resetCustomerAppointmentData());
                },
                (e) => {
                    toastr.error("Something went wrong. Please try again later");
                }
            )
            .finally(() => {
                setData(resetCustomerAppointmentData);
            });
    }

    function emitOnTimerExpire() {
        appointmentService
            .abandonAppointment(data.id)
            .then(
                (d) => {
                    let _timerOptions = timerOptions;
                    _timerOptions.timerStatus = TimerStatusEnum.Main;

                    setTimerOptions(_timerOptions);
                },
                (e) => {
                    toastr.error("Something went wrong. Please try again later");
                }
            )
            .finally(() => {
                setData(resetCustomerAppointmentData());
                setActiveStep(1);
                navigate('/');
            });


    }

    function applyDiscountCode() {
        setIsDiscountCodeApplied(false);
        setIsDiscountCodeValid(true);
        if (data.id && data.discountCode && data.discountCode.length > 0) {
            appointmentService
                .getPriceUsingDiscountCode(data.id, data.discountCode)
                .then(
                    (d) => {
                        setPaymentAmount(d.paymentAmount);
                        setIsDiscountCodeApplied(d.isDiscountCodeValid);
                        setIsDiscountCodeValid(d.isDiscountCodeValid);
                        discountCodeId = d.discountCodeId;
                    },
                    (e) => {
                        toastr.error("Something went wrong. Please try again later");
                    }
                )
                .finally(() => {
                });
        }
    }

    return (
        <>
            <Box
                sx={{
                    width: { xs: '95%', lg: '930px' },
                    display: 'flex',
                    margin: "0 auto",
                    marginBottom: "4px",
                    flexWrap: 'wrap',
                    bgcolor: '#FFFFFF',
                    borderRadius: { xs: "20px", md: '40px!important' },
                    boxShadow: { xs: '5px 5px 20px #9CA7B24D', sm: '0px 3px 40px #9CA7B233' },
                    opacity: 1
                }}
            >
                {(activePage === JourneyStepsEnum.CustomerDetailsStep || activePage === JourneyStepsEnum.PaymentStep) &&
                    <Box width="100%" mt={4}>
                        <AppBar sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            backgroundColor: PRIMARY_COLOR[4],
                            fontSize: '1.1em',
                            marginTop: '85px'
                        }}>
                            Appointment time reserved for: <JourneyTimer
                                timerOptions={timerOptions}
                                emitOnTimerExpire={emitOnTimerExpire} />
                        </AppBar>
                    </Box>
                }

                {activePage !== JourneyStepsEnum.AppointmentConfirmed &&
                    <Box width={{ xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" }} mt={{ xs: 6, md: 4 }}>
                        <JourneyStepper steps={stepperSteps} activeStep={activeStep} />
                    </Box>
                }


                <Box
                    width={{ xs: "90%", md: "100%" }}
                    margin={{ xs: "15px auto", md: "auto" }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    // boxShadow={{ xs: "5px 5px 20px #9CA7B24D", md: "none" }}
                    borderRadius={{ xs: "20px", md: "" }}
                >
                    {(activePage === JourneyStepsEnum.ServiceDetailsStep || activePage === JourneyStepsEnum.CustomerDetailsStep || activePage === JourneyStepsEnum.PaymentStep) &&

                        <ServiceCardBox>

                            <Box display="flex" justifyItems="flex-end"
                                m="0 auto"
                                component="img"
                                height={{ xs: "70px", md: "96px" }}
                                alt="Service Type"
                                src={SERVICE_IMAGE(params.serviceType)}

                            />

                            <Box width={{ xs: "55%", md: "58%" }} display="flex" flexDirection="column" alignContent="center">

                                <Typography
                                    align="left"
                                    fontSize={{ xs: '14px', md: '20px' }}
                                    fontFamily="Poppins, sans-serif"
                                    fontWeight='500'>
                                    {consultationType}<br />
                                    Consultation
                                </Typography>

                                {activePage === JourneyStepsEnum.CustomerDetailsStep ?

                                    <Typography
                                        align="left"
                                        margin={0}
                                        padding={0} fontSize={{ xs: '14px', md: '20px !important' }} fontFamily="Poppins, sans-serif" fontWeight='300'>
                                        {formattedDateTime.dayWithSuffix}&nbsp;{formattedDateTime.monthYear} < br /> {formattedDateTime.time}
                                    </Typography>
                                    :
                                    <>
                                        {(servicePrice !== 0 && !isLoading) &&
                                            <Typography align="left" margin={0} padding={0} fontSize={{ xs: '25px', md: '40px !important' }} fontFamily="Poppins, sans-serif" fontWeight='400'>
                                                &#8364;{servicePrice}
                                            </Typography>}
                                        {(servicePrice === 0 && !isLoading) &&
                                            <Typography align="left" fontSize={{ xs: '1.2em !important', sm: '1.6em', md: '40px !important' }} fontFamily="Poppins, sans-serif" fontWeight='normal'>
                                                "FREE"
                                            </Typography>}
                                    </>
                                }
                            </Box>

                        </ServiceCardBox>
                    }

                    <Box>
                        <Box>
                            {activePage === JourneyStepsEnum.ServiceDetailsStep && <ServiceDetailsStep
                                serviceDetails={serviceDetails}
                                servicePrice={servicePrice} />}

                            {activePage === JourneyStepsEnum.InitialDetailsStep && <InitialDetailsStep
                                data={data}
                                serviceType={params.serviceType}
                                onBack={onBack}
                                onNext={onNext}
                                onChange={handleChange}
                            />}

                            {activePage === JourneyStepsEnum.AppointmentPickerStep && <AppointmentPickerStep
                                data={data}
                                serviceType={consultationType}
                                serviceId={serviceId}
                                appointmentMinDaysInAdvance={appointmentMinDaysInAdvance}
                                appointmentMaxDaysInAdvance={appointmentMaxDaysInAdvance}
                                onChange={handleChange}
                                onAppointmentTimeSelected={onAppointmentTimeSelected} />}

                            {activePage === JourneyStepsEnum.CustomerDetailsStep && <CustomerDetailsStep
                                data={data}
                                onChange={handleChange}
                                serviceType={params.serviceType}
                                servicePrice={servicePrice}
                                onApplyDiscountCode={applyDiscountCode}
                                isDiscountCodeApplied={isDiscountCodeApplied}
                                isDiscountCodeValid={isDiscountCodeValid}
                                onBack={onBack}
                                onNext={onNext}
                                appointmentDate={data.appointmentDate}
                            />}

                            {activePage === JourneyStepsEnum.PaymentStep && <PaymentStep
                                paymentAmount={paymentAmount}
                                discountCodeId={discountCodeId}
                                customerEmail={data.email}
                                onSubmitPayment={submitPayment} />}

                            {activePage === JourneyStepsEnum.AppointmentConfirmed && <AppointmentConfirmedStep data={data} />}
                        </Box>
                    </Box>
                </Box>

                <Box width="100%" marginTop={{ xs: "10px", md: "20px" }} marginBottom={{ xs: "20px", md: "45px" }} marginLeft={{ xs: "30px", md: "48px" }} marginRight={{ xs: "30px", md: "48px" }}>
                    {activePage !== JourneyStepsEnum.CustomerDetailsStep && activePage !== JourneyStepsEnum.AppointmentConfirmed &&
                        <NavButtons
                            showBack={true}
                            showNext={(activePage === JourneyStepsEnum.ServiceDetailsStep)
                                || (activePage === JourneyStepsEnum.AppointmentPickerStep && isAppointmentTimeSelected === 'true')
                                || ((serviceData.name == "Mortgage" ? activePage === JourneyStepsEnum.InitialDetailsStep && isIncomeBandSelected === true && data.mortgageTypeId != null && data.mortgageAmountRequired != null : activePage == JourneyStepsEnum.InitialDetailsStep && isIncomeBandSelected === true)
                                    || (activePage == JourneyStepsEnum.InitialDetailsStep
                                        && (data.mortgageTypeId !== null && data.mortgageTypeId !== undefined
                                            && data.mortgageAmountRequired !== null && data.mortgageAmountRequired !== undefined
                                            && data.mortgageAmountRequired > 0)))}
                            isNextComplete={false}
                            isDisableAllNav={isDisableAllNav}
                            isSubmit={false}
                            onBack={onBack}
                            onNext={onNext}
                        />
                    }
                </Box>
            </Box>
        </>
    );
}