import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../_components/button/primary.button";
import { PRIMARY_COLOR } from "../../../_const/color.const";

export const AppointmentCancelled = () => {
    let navigate = useNavigate();

    return (
        <Box display="flex" flexDirection="column" justifyContent="center">
            <Box margin={5}>
                <Typography align="center" fontFamily="Poppins" variant="h5" sx={{ color: PRIMARY_COLOR[0] }}>
                    Your appointment is now cancelled!
                    <br />
                    An email will arrive in your inbox confirming your appointment cancelation.
                    <br />
                    If payment was made a member of our team will be in touch to arrange a refund.
                    <br />
                    <span style={{ fontSize: "30px", fontWeight: "600" }}>Thank you!</span>
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                <PrimaryButton
                    variant="fairstoneConfirmButton"
                    type="button"
                    onClick={(e) => navigate('/')}
                >
                    Book Another Appointment
                </PrimaryButton>
            </Box>
        </Box>
    );
}