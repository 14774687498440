import { API_URL } from "../_config/config.const";
import { getBody, getBodyWithNestedObject } from "../_helpers/body.helper";
import { handleResponse } from "../_helpers/handleResponse.helper";
import { getHeader } from "../_helpers/header.helper";
import { getQuery } from "../_helpers/query.helper";

const API_NAME = "appointment";

export const appointmentService = {
    getAll,
    get,
    submit(model?: any) { return model?.id ? put(model) : post(model) },
    disable,
    getActiveServices,
    getAllAppointmentTimesForDay,
    customerAppointmentSubmit(model?: any) { return model?.id ? customerAppointmentUpdate(model) : customerAppointmentCreate(model) },
    abandonAppointment(model: any) { return abandonAppointment(model) },
    customerAppointmentCreateComplete,
    getCustomerAppointment,
    cancelCustomerAppointment,
    getPriceUsingDiscountCode(appointmentId: number, discountCode: string) { return getPriceUsingDiscountCode(appointmentId, discountCode)}
};

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetAll?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/Get/?id=${id}`, requestOptions).then(handleResponse);
}

function post(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/create`, requestOptions).then(handleResponse);
}

function put(model: any) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/update`, requestOptions).then(handleResponse);
}

function disable(id: any) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/disable/?appointmentId=${id}`, requestOptions).then(handleResponse);
}

function getAllAppointmentTimesForDay(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetAllAppointmentTimesForDay?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function customerAppointmentCreate(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBodyWithNestedObject(model) };
    return fetch(`${API_URL}/${API_NAME}/customerAppointmentCreate`, requestOptions).then(handleResponse);
}

function customerAppointmentUpdate(model: any) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBodyWithNestedObject(model) };
    return fetch(`${API_URL}/${API_NAME}/customerAppointmentUpdate`, requestOptions).then(handleResponse);
}

function abandonAppointment(id: any) {
    const requestOptions = { method: 'PUT', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/abandonAppointment?appointmentId=${id}`, requestOptions).then(handleResponse);
}

function customerAppointmentCreateComplete(model: any) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/customerAppointmentCreateComplete`, requestOptions).then(handleResponse);
}

function getCustomerAppointment(token: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetCustomerAppointment/?token=${token}`, requestOptions).then(handleResponse);
}

function cancelCustomerAppointment(id: any, cancellationReason: string) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/CancelCustomerAppointment/?appointmentId=${id}&cancellationReason=${cancellationReason}`, requestOptions).then(handleResponse);
}

function getActiveServices() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getActiveServices`, requestOptions).then(handleResponse);
}

function getPriceUsingDiscountCode(appointmentId: number, discountCode: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getPriceUsingDiscountCode/?appointmentId=${appointmentId}&discountCode=${discountCode}`, requestOptions).then(handleResponse);
}